import Vue from 'vue'
import App from './App.vue'
import router from './router'
import notify from './externals/notify'


import Flutterwave from  'flutterwave-vue-v3'
// Vue.use(Flutterwave, { publicKey: 'FLWPUBK-7d4a6c249c2f446a6e0ebf8119bce3cb-X' } )
Vue.use(Flutterwave, { publicKey: 'FLWPUBK_TEST-6998959b26d901a2d65677102c368eef-X' } )


import axios from 'axios'
Vue.prototype.$http = axios
// axios.defaults.baseURL = 'http://localhost/ikotun/index.php/'
axios.defaults.baseURL = 'https://devapi.ikotunconnect.com/index.php/'
// axios.defaults.baseURL = 'https://api.ikotunconnect.com/index.php/'


import VueKeepScrollPosition from 'vue-keep-scroll-position'
Vue.use(VueKeepScrollPosition)


import TextareaAutosize from 'vue-textarea-autosize'
Vue.use(TextareaAutosize)


import VueNumeric from 'vue-numeric'
Vue.use(VueNumeric)


import Croppa from 'vue-croppa';
Vue.use(Croppa)


import vuescroll from 'vue-scroll'
Vue.use(vuescroll)


import { longClickDirective } from 'vue-long-click'

const longClickInstance = longClickDirective({delay: 200, interval: 50})
Vue.directive('longclick', longClickInstance)


import VueSocketIO from 'vue-socket.io';
import SocketIO from 'socket.io-client'

Vue.use(new VueSocketIO({
    debug: true,
    // connection: SocketIO('http://localhost:3000'),
    connection: SocketIO('https://www.devsocket.ikotunconnect.com'),
    // connection: SocketIO('https://christianolu.alwaysdata.net'),
    // connection: SocketIO('https://socket.ikotunconnect.com/'),
}));


import ImageKit from "imagekitio-vue"
Vue.use(ImageKit, {
    urlEndpoint: "https://ik.imagekit.io/ikotunconnect/"
})

import {VueMasonryPlugin} from 'vue-masonry'
import './registerServiceWorker'
Vue.use(VueMasonryPlugin)



Vue.config.productionTip = false


import moment from 'moment-timezone'

new Vue({
    router,
    notify,
    beforeCreate() {
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        console.log('timezone', timezone);
        moment.tz.setDefault('Etc/GMT');
    },
    data() {
        return {
            mainHeight: '',

            pwa_install_prompt: null,

            app_version: '0.12',
            is_splash: false,
            login: false,
            email_verified: false,
            dp: '',
            cover: '',
            created: '',
            verified: '',
            firstname: '',
            lastname: '',
            gender: '',
            username: '',
            location: '',
            email: '',

            posts: 0,
            comments: 0,
            followers: 0,
            followings: 0,

            c: {
                main: 'rgb(106, 13, 173)',
                trans: 'rgba(106, 13, 173, 0.2)',
                m: 'royalblue',
                t: 'rgba(65, 105, 225, 0.3)'
            },

            pop: false,
            pop_item: '',
            pop_prop: null,

            new_follow: {},
            new_hide: {},
            new_block: {},
            new_unblock: {},
            new_edit: {},
            new_delete: {},
            new_react: {},
            new_transaction: {},

            write_reply: {},
            view_replys: {},


            chat_approved: {},
            chat_declined: {},

            
            chat_updated: {},


            new_message_sent: {},
            new_message_received: {},
            message_delivered_info: {},
            message_seen_info: {},

            chat_requests: 0,

            message_notification: false,


            notifications: 0,
            notification_seen: 0
        }
    },
    render: h => h(App)
}).$mount('#app')
