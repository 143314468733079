import Vue from 'vue'
import VueRouter from 'vue-router'

import splash from '../views/start/splash.vue'

const info = () =>  import('../views/start/info.vue')
const membership = () => import('../views/start/membership.vue')
const verify = () => import('../views/start/verify.vue')

const home = () => import('../views/Home.vue')
const notifications = () => import('../views/notifications.vue')

const overview = () => import('../views/overview.vue')

const messages_view = () => import('../views/message/view.vue')
const chat = () => import('../views/message/chat.vue')

const activity = () => import('../views/activity/view.vue')
const activity_help = () => import('../views/activity/help.vue')

const elections = () => import('../views/elections/view.vue')
const elections_learn = () => import('../views/elections/learn.vue')

const market_view = () => import('../views/market/view.vue')
const market_help = () => import('../views/market/help.vue')

const wallet_view = () => import('../views/wallet/wallet.vue')
const topup = () => import('../views/wallet/ngn/topup.vue')

const people_view = () => import('../views/people/view.vue')

const create_post = () => import('../views/post/create.vue')
const edit_post = () => import('../views/post/edit.vue')
const delete_post = () => import('../views/post/delete.vue')
const post_settings = () => import('../views/post/settings.vue')
const post_view = () => import('../views/post/view.vue')

const comment_view = () => import('../views/comment/view.vue')

const profile = () => import('../views/profile/view.vue')
const profile_edit = () => import('../views/profile/edit.vue')
const profile_settings = () => import('../views/profile/settings.vue')

const event_page = () => import('../views/event/event.vue')

const rules = () => import('../views/city/rules.vue')
const about = () => import('../views/city/about.vue')
const terms = () => import('../views/terms.vue')



// import info from '../views/start/info.vue'
// import membership from '../views/start/membership.vue'
// import verify from '../views/start/verify.vue'

// import home from '../views/Home.vue'
// import notifications from '../views/notifications.vue'

// import overview from '../views/overview.vue'

// import messages_view from '../views/message/view.vue'
// import chat from '../views/message/chat.vue'

// import activity from '../views/activity/view.vue'
// import activity_help from '../views/activity/help.vue'

// import elections from '../views/elections/view.vue'
// import elections_learn from '../views/elections/learn.vue'

// import market_view from '../views/market/view.vue'
// import market_help from '../views/market/help.vue'

// import people_view from '../views/people/view.vue'

// import create_post from '../views/post/create.vue'
// import edit_post from '../views/post/edit.vue'
// import delete_post from '../views/post/delete.vue'
// import post_settings from '../views/post/settings.vue'
// import post_view from '../views/post/view.vue'

// import comment_view from '../views/comment/view.vue'

// import profile from '../views/profile/view.vue'
// import profile_edit from '../views/profile/edit.vue'

// import event_page from '../views/event/event.vue'

// import rules from '../views/city/rules.vue'
// import about from '../views/city/about.vue'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'splash',
        component: splash
    },
    {
        path: '/info',
        name: 'info',
        component: info
    },
    {
        path: '/membership',
        name: 'membership',
        component: membership
    },
    {
        path: '/verify',
        name: 'verify',
        component: verify
    },
    {
        path: '/home',
        name: 'home',
        component: home,
        meta: {
            transition: 'fade'
        }
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: notifications
    },

    {
        path: '/overview',
        name: 'overview',
        component: overview
    },


    {
        path: '/message',
        name: 'message',
        component: messages_view,
        meta: {
            transition: 'fade'
        }
    },
    {
        path: '/chat/:id',
        name: 'chat',
        component: chat
    },


    {
        path: '/activity',
        name: 'activity',
        component: activity
    },
    {
        path: '/activity/help',
        name: 'activity_help',
        component: activity_help
    },


    {
        path: '/elections',
        name: 'elections',
        component: elections
    },
    {
        path: '/elections/learn',
        name: 'elections_learn',
        component: elections_learn
    },


    {
        path: '/market',
        name: 'market',
        component: market_view
    },
    {
        path: '/market/help',
        name: 'market_help',
        component: market_help
    },

    {
        path: '/wallet',
        name: 'wallet',
        component: wallet_view,
        meta: {
            transition: 'fade'
        }
    },
    {
        path: '/topup',
        name: 'topup',
        component: topup
    },

    {
        path: '/people',
        name: 'people',
        component: people_view,
        meta: {
            transition: 'fade'
        }
    },

    
    {
        path: '/create_post',
        name: 'create_post',
        component: create_post,
        meta: {
            'transition': 'slide_up'
        }
    },
    {
        path: '/edit_post',
        name: 'edit_post',
        component: edit_post
    },
    {
        path: '/delete_post',
        name: 'delete_post',
        component: delete_post
    },
    {
        path: '/post_settings',
        name: 'post_settings',
        component: post_settings
    },
    {
        path: '/post/:id',
        name: 'post',
        component: post_view
    },
    {
        path: '/comment/:id',
        name: 'comment',
        component: comment_view
    },
    {
        path: '/profile/edit',
        name: 'profile',
        component: profile_edit
    },
    {
        path: '/profile/settings',
        name: 'settings',
        component: profile_settings
    },
    {
        path: '/profile/:username',
        name: 'profile',
        component: profile,
        meta: {
            transition: 'fade'
        }
    },

    {
        path: '/event/:id',
        name: 'event_page',
        component: event_page
    },

    {
        path: '/city/rules',
        name: 'rules',
        component: rules
    },
    {
        path: '/city/about',
        name: 'about',
        component: about
    },
    {
        path: '/terms',
        name: 'terms',
        component: terms
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    if (to.name == 'splash') {
        next(false)
    }
    next()
})

export default router
